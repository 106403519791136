import React, { useState } from "react"
import { Modal, Col, Row, Button, Container } from "react-bootstrap"
import logisticEquation from "../images/equations/logistic-equation-1.svg"
import logisticEquation2 from "../images/equations/logistic-equation-2.svg"
import logisticEquation3 from "../images/equations/logistic-equation-3.svg"
// import TeX from "@matejmazur/react-katex"
import parse from "html-react-parser"
import { Trans, useTranslation } from "gatsby-plugin-react-i18next"
// =========================================================================================================
const MyModal = props => {
  const { t } = useTranslation()
  const [activeTab, setActiveTab] = useState(0)
  // export default function modal(props) {

  const pageContent = {
    title: parse(t("RmaxModal_title")),
    topText: parse(t("RmaxModal_topText")),
    bottomText: [
      parse(t("RmaxModal_bottomText[0]")),
      parse(t("RmaxModal_bottomText[1]")),
      parse(t("RmaxModal_bottomText[2]")),
      parse(t("RmaxModal_bottomText[3]")),
    ],
    rmax: [
      parse(t("RmaxModal_rmax[0]")),
      parse(t("RmaxModal_rmax[1]")),
      parse(t("RmaxModal_rmax[2]")),
      parse(t("RmaxModal_rmax[3]")),
      parse(t("RmaxModal_rmax[4]")),
      parse(t("RmaxModal_rmax[5]")),
      parse(t("RmaxModal_rmax[6]")),
      parse(t("RmaxModal_rmax[7]")),
    ],
    image: parse(t("RmaxModal_imgs[0].text")),
    r: [
      parse(t("RmaxModal_r[0]")),
      parse(t("RmaxModal_r[1]")),
      parse(t("RmaxModal_r[2]")),
      parse(t("RmaxModal_r[3]")),
      parse(t("RmaxModal_r[4]")),
      parse(t("RmaxModal_r[5]")),
      parse(t("RmaxModal_r[6]")),
      parse(t("RmaxModal_r[7]")),
    ],
  }

  // =========================================================================================================
  return (
    <Modal {...props} aria-labelledby="contained-modal-title-vcenter">
      {/* <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Using Grid in Modal
        </Modal.Title>
      </Modal.Header> */}
      <Modal.Body className="show-grid">
        <Container>
          <Modal.Header closeButton>
            <h3>{pageContent.title}</h3>
          </Modal.Header>
          <br />
          <p>{pageContent.topText}</p>

          <Row>
            <Col
              sm="6"
              className={activeTab == 0 ? "rmaxTabActive" : "rmaxTab"}
              onClick={() => {
                setActiveTab(0)
              }}
            >
              <center>
                <h3>{pageContent.rmax[0]}</h3>
                <p>{pageContent.rmax[1]}</p>
              </center>
            </Col>
            <Col
              sm="6"
              className={activeTab == 1 ? "rmaxTabActive" : "rmaxTab"}
              onClick={() => {
                setActiveTab(1)
              }}
            >
              <center>
                <h3>{pageContent.r[0]}</h3>
                <p>{pageContent.r[1]}</p>
              </center>
            </Col>
          </Row>
          {/* rmax */}
          <div hidden={activeTab != 0}>
            <Row
              style={{
                border: "2px solid #19484D",
                borderBottomLeftRadius: "5px",
                borderBottomRightRadius: "5px",
                padding: "20px 10px",
              }}
            >
              <Col md="4">
                <p style={{ height: "30px" }}>
                  <strong>{pageContent.rmax[2]}</strong>
                </p>
                <hr />
                <p style={{ height: "80px" }}>{pageContent.rmax[4]}</p>
                <hr />
                <p>{pageContent.rmax[6]}</p>
              </Col>
              <br />
              <Col md="8">
                <p style={{ height: "30px" }}>
                  <strong>{pageContent.rmax[3]}</strong>
                </p>
                <hr />
                <p style={{ height: "80px" }}>{pageContent.rmax[5]}</p>
                <hr />
                <p>{pageContent.rmax[7]}</p>
              </Col>
            </Row>
          </div>
          {/* r */}
          <div hidden={activeTab != 1}>
            <Row
              style={{
                border: "2px solid #19484D",
                borderBottomLeftRadius: "5px",
                borderBottomRightRadius: "5px",
                padding: "20px 10px",
              }}
            >
              <Col md="4">
                <p style={{ height: "30px" }}>
                  <strong>{pageContent.r[2]}</strong>
                </p>
                <hr />
                <p style={{ height: "80px" }}>{pageContent.r[4]}</p>
                <hr />
                <p>{pageContent.r[6]}</p>
              </Col>
              <br />
              <Col md="8">
                <p style={{ height: "30px" }}>
                  <strong>{pageContent.r[3]}</strong>
                </p>
                <hr />
                <p style={{ height: "80px" }}>{pageContent.r[5]}</p>
                <hr />
                <p>{pageContent.r[7]}</p>
              </Col>
            </Row>
          </div>
          <br />
          <p>{pageContent.bottomText[0]}</p>
          <center>
            <img
              src={logisticEquation}
              alt={parse(t("RmaxModal_imgs[0].alt"))}
            />
            <p className="smText">{pageContent.image}</p>
          </center>
          <p>{pageContent.bottomText[1]}</p>
          <center>
            <img
              src={logisticEquation2}
              alt={parse(t("RmaxModal_imgs[1].alt"))}
            />
          </center>
          <p>{pageContent.bottomText[2]}</p>
          <center>
            <img
              src={logisticEquation3}
              alt={parse(t("RmaxModal_imgs[2].alt"))}
            />
          </center>
          <p>{pageContent.bottomText[3]}</p>
        </Container>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="info" className="btnNext" onClick={props.onHide}>
          {parse(t("Close"))}
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

// example:=======================================
// function App() {
//   const [modalShow, setModalShow] = useState(false)

//   return (
//     <>
//       <Button variant="primary" onClick={() => setModalShow(true)}>
//         Launch modal with grid
//       </Button>

//       <MydModal show={modalShow} onHide={() => setModalShow(false)} />
//     </>
//   )
// }

export default MyModal
