import { Link } from "gatsby"
import PropTypes from "prop-types"
import React, { useState } from "react"
import { Navbar, Nav, NavDropdown } from "react-bootstrap"
import MyModal from "./rmaxModal"
import { Link as Linki18, useI18next } from "gatsby-plugin-react-i18next"
import parse from "html-react-parser"
import { Trans, useTranslation } from "gatsby-plugin-react-i18next"

const Header = ({ siteTitle }) => {
  const { t } = useTranslation()
  const [modalShow, setModalShow] = useState(false)
  const { languages, originalPath, language } = useI18next()

  return (
    <header
      id="myHeader"
      style={{
        background: `#19484C`,
        marginBottom: `1.45rem`,
        padding: "10px",
      }}
    >
      {/* <div
        style={{
          margin: `0 auto`,
          maxWidth: 960,
          padding: `1.45rem 1.0875rem`,
        }}
      >
        <h1 style={{ margin: 0 }}>
          <Link
            to="/"
            style={{
              color: `white`,
              fontSize: "18px",
              textDecoration: `none`,
            }}
          >
            {siteTitle}
          </Link>
        </h1>
      </div> */}
      <Navbar
        collapseOnSelect
        expand="lg"
        bg="lightOFF"
        variant="dark"
        className=""
        style={{
          margin: `0 auto`,
          maxWidth: 1060,
          padding: `0 1.0875rem`,
        }}
      >
        <Navbar.Brand className="highlightColor">
          <Link to="/">
            <span className="whiteLink">{parse(t("Header_title"))}</span>
          </Link>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="ml-auto">
            <Nav.Link id="navLink">
              <Link to="/">
                <span className="whiteLink">{parse(t("Header_home"))}</span>
              </Link>
            </Nav.Link>
            <NavDropdown
              title={parse(t("Header_topics"))}
              id="collasible-nav-dropdown"
            >
              <NavDropdown.Item>
                <Link to="/exponentialgrowth">
                  <span className="noUnder">{parse(t("Header_topic1"))}</span>
                </Link>
              </NavDropdown.Item>
              <NavDropdown.Item>
                <Link to="/logisticgrowth">
                  <span className="noUnder">{parse(t("Header_topic2"))}</span>
                </Link>
              </NavDropdown.Item>
              <NavDropdown.Item>
                <Link to="/epopulationgrowth">
                  <span className="noUnder">{parse(t("Header_topic3"))}</span>
                </Link>
              </NavDropdown.Item>
            </NavDropdown>
          </Nav>
        </Navbar.Collapse>
        <button class="buttonT button5" onClick={() => setModalShow(true)}>
          r
        </button>
        <Nav.Link id="navLink" style={{ fontSize: "14px" }}>
          {/* <Link to={"/"} style={{ color: "white" }}> */}
          <Linki18
            to={originalPath}
            language={language == "en" ? "fr" : "en"}
            style={{ color: "white" }}
          >
            <span className="noUnder">{parse(t("Header_language"))}</span>
          </Linki18>
        </Nav.Link>
        {/* <ul className="languages">
          {languages.map(lng => (
            <li key={lng}>
              <Linki18
                to={originalPath}
                language={lng}
                style={{ color: "white" }}
              >
                {lng}
              </Linki18>
            </li>
          ))}
        </ul> */}
        <MyModal
          size="xl"
          className="rModal"
          show={modalShow}
          onHide={() => setModalShow(false)}
          centered
        />
      </Navbar>
    </header>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
