import { Link } from "gatsby"
import React from "react"
import { Row, Col } from "react-bootstrap"
import ulogo from "../images/footer/uottawa_hor_white.png"
import flogo from "../images/footer/by-nc-sa.svg"
import parse from "html-react-parser"
import { Trans, useTranslation } from "gatsby-plugin-react-i18next"
// =========================================================================================================
const Footer = ({ siteTitle }) => {
  const { t } = useTranslation()

  // =========================================================================================================
  return (
    <footer className="footerDiv">
      <div
        className="footerContentOFF"
        style={{
          margin: `0 auto`,
          maxWidth: 1080,
          padding: `0 1.0875rem`,
        }}
      >
        <Row>
          <Col md="2">
            <a href={parse(t("Footer_cc.link"))} target="_blank">
              <img src={flogo} alt={parse(t("Footer_cc.alt"))} />
            </a>
          </Col>
          <Col md="8">
            <p>
              {parse(t("Footer_design"))}
              <a
                href={parse(t("Footer_uni.link"))}
                target="_blank"
                className="footerLink"
              >
                {parse(t("Footer_uni.text"))}
              </a>
              <br />
              {/* . © Copyright {new Date().getFullYear()}. */}
              {parse(t("Footer_adapted"))}
              <a
                href="https://ats.doit.wisc.edu/biology/ec/pd/pd.htm"
                target="_blank"
                className="footerLink"
              >
                {parse(t("Footer_wisc.text"))}
              </a>{" "}
              <a
                href={parse(t("Footer_cc.link"))}
                target="_blank"
                className="footerLink"
              >
                {parse(t("Footer_cc.text"))}
              </a>
              .
            </p>
          </Col>

          <Col md="2">
            {" "}
            <a href={parse(t("Footer_uni.link2"))} target="_blank">
              <img src={ulogo} alt={parse(t("Footer_uni.alt"))} />
            </a>
          </Col>
        </Row>
      </div>
    </footer>
  )
}

export default Footer
